export const PERFIL = {
    MEDICO_SOLICITANTE: 'medicoSolicitante',
    MEDICO_EXECUTOR: 'medicoExecutor',
    MEDICO_LAUDADOR: 'medicoLaudador',
    ADMINISTRADOR: 'administrador',
    ADMINISTRADOR_ESTADUAL: 'adminEstadual',
    TECNICO: 'tecnico',
    VISUALIZADOR: 'visualizador',
    VISUALIZADOR_REDE: 'visualizadorRede'
}

export const TIPO_ANEXO = {
    PDF: 'pdf',
    BMP: 'bmp'
}

export const VINCULO_PERFIL = {
    GLOBAL: 'G',
    INSTITUICAO: 'I',
    USUARIO: 'U',
    REDE: 'R'
}

export const TIPO_ABA_EXAME = {
    INSTITUICAO: 'INSTITUICAO',
    REDE: 'REDE',
    PERFIL: 'PERFIL'
}

export const SITUACAO_LAUDO = {
    COM_LAUDO: 'S',
    SEM_LAUDO: 'N',
    LAUDO_TEMPORARIO: 'T',
    LAUDO_EMISSAO: 'E',
    LAUDO_AGUARDANDO_COMPLEMENTO: 'A',
    DIGITADO: 'D',
    COM_LAUDO_APLICATIVO: 'M',
    AGUARDANDO_TRANSCRICAO: 'I'
}

export const SITUACAO = {
    VALIDO: 'V',
    INVALIDO: 'I'
}

export const SITUACOES_DESCRICAO = [
    { sigla: 'T', descricao: 'Todos' },
    { sigla: 'A', descricao: 'Ativos' },
    { sigla: 'I', descricao: 'Inativos' }
]

export const CONSELHO_TRABALHO = {
    CRM: 'CRM'
}

export const PRODUTIVIDADE = {
    LAUDADOS: 'LAUDADOS',
    LAUDO_72_HORAS: 'LAUDO_72_HORAS',
    SEM_LAUDO: 'SEM_LAUDO',
    VALIDOS: 'VALIDOS',
    INVALIDOS: 'INVALIDOS'
}

export const ORIGEM_MANUAL = 'M';

export const TIPO_EXAME = {
    ELETROCARDIOGRAFIA: 'ELETROCARDIOGRAFIA'
}

export const MODALIDADE = {
    SIGLA: 'ECG',
    DESCRICAO: 'Electrocardiograms - General',
    CODIGO: '1.2.840.10008.5.1.4.1.1.9.1.2',
    DESCRICAO_PT: 'Eletrocardiograma'
};

export const CONSTANTES_PRE_OPERATORIO = {
    SIM: 'S',
    NAO: 'N'
}

export const CONSTANTES_INVESTIGACAO_DOR_DOR_MOMENTO_EXAME = {
    SIM: 'S',
    NAO: 'N'
}

export const CONSTANTES_ENVIO_EXAME_CLASSIFICAR_DOR_TORACICA = {
    SIM: 'S',
    NAO: 'N'
}

export const PERMISSOES = {
    'ELETROCARDIOGRAFIA': 'ELETROCARDIOGRAFIA_ELETROCARDIOGRAFIA',
    'SOLICITACAO': 'ELETROCARDIOGRAFIA_SOLICITACAO',
    'CRIAR_EXAME': 'ELETROCARDIOGRAFIA_CRIAR_EXAME',
    'VISUALIZAR_EXAME': 'ELETROCARDIOGRAFIA_VISUALIZAR_EXAME',
    'VISUALIZAR_IMAGEM': 'ELETROCARDIOGRAFIA_VISUALIZAR_IMAGEM',
    'VISUALIZAR_LAUDO': 'ELETROCARDIOGRAFIA_VISUALIZAR_LAUDO',
    'PRIORIZAR_EXAME': 'ELETROCARDIOGRAFIA_PRIORIZAR_EXAME',
    'INVALIDAR_EXAME': 'ELETROCARDIOGRAFIA_INVALIDAR_EXAME',
    'LAUDAR_EXAME': 'ELETROCARDIOGRAFIA_LAUDAR_EXAME',
    'LAUDAR_EXAME_FABRICA': 'ELETROCARDIOGRAFIA_LAUDAR_EXAME_FABRICA',
    'VISUALIZAR_SOLICITACAO': 'ELETROCARDIOGRAFIA_VISUALIZAR_SOLIC_EX',
    'INVALIDAR_EXAME_LAUDADO': 'ELETROCARDIOGRAFIA_INVALIDAR_EXAME_LAUD',
    'TROCAR_REDE': 'ELETROCARDIOGRAFIA_TROCAR_REDE',
    'ADMINISTRATIVO': 'ELETROCARDIOGRAFIA_ADMINISTRATIVO'
}

export const CONSTANTES_ESPECIFICACAO_TEMPO_APRESENTA_SINTOMA = [
    { id: 1, descricao: 'Hora(s)' },
    { id: 2, descricao: 'Dia(s)' },
    { id: 3, descricao: 'Semana(s)' }
];

export const CONSTANTES_INTENSIDADE_DOR = [
    { id: 1, descricao: '1 - muito fraca' },
    { id: 2, descricao: '2' },
    { id: 3, descricao: '3' },
    { id: 4, descricao: '4' },
    { id: 5, descricao: '5' },
    { id: 6, descricao: '6' },
    { id: 7, descricao: '7' },
    { id: 8, descricao: '8' },
    { id: 9, descricao: '9' },
    { id: 10, descricao: '10 - muito forte' },
];

export const CONSTANTES_MOTIVOS_SOLICITACAO = {
    DISPNEIA: 'DI',
    PALPITACAO: 'PA',
    DOR_TORACICA: 'DT',
    SINCOPE: 'SI',
    CRONICA: 'C',
    AGUDA: 'A',
    ISOLADA: 'I',
    RECORRENTE: 'R',
    PRE_OPERATORIO: 'PO'
}

export const CONSTANTES_MOTIVOS_PRIORIZACAO = {
    SINTOMATICO: 'SINTOMATICO',
    PRE_OPERATORIO: 'PRE-OPERATORIO'
};

export const DESCRICAO_MODALIDADE_SERVICOS_MONITORADOS = 'Eletrocardiograma';

export const DESCRITORES_NORMAIS = {
    NORMAL: 'NORMAL/DLN'
}

export const LAUDO_MODIFICADOR_NENHUM = 'Nenhum';

export const PRIORIDADE_GRAVE = 1;

export const ORDENACAO = {
    DIRECAO: {
        ASC: 'asc',
        DESC: 'desc'
    },
    CAMPOS: {
        DESCRICAO: 'descricao',
        ATIVO: 'ativo',
        VISUALIZA_SIGLA: 'visualiza_sigla',
        VISUALIZA_NOME: 'visualiza_nome',
        VISUALIZA_DESCRICAO: 'visualiza_descricao',
        VISUALIZA_INFORMACOES_COMPLEMENTARES: 'visualiza_informacoes_complementares',
        VISUALIZA_PRIORIDADE: 'visualiza_prioridade'
    }
};

export const SITUACOES_LAUDO_GRAVE = [
    { identificador: 'NV', descricao: 'Não visualizado' },
    { identificador: 'VI', descricao: 'Visualizado' },
    { identificador: 'VE', descricao: 'Verificado' },
]

export const RELACAO_INSTITUICAO_MODALIDADE_SERVICO = 'S';

export const IDENTIFICADOR_TIPO_MODALIDADE = 1;

export const ORIGEM_PACIENTE_PROVISORIO = 'P';